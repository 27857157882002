import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { clsx } from 'clsx';

import { answersType } from '../../../types';

import RightArrow from '../../../assets/right-arrow.svg';

export const NextStepVariants = {
	Default:
		'group flex items-center justify-between p-5 my-2 border-solid rounded-sm border-1 next-step',
	Dark: 'border-blue-200 text-brand-blue bg-white',
	Light: 'border-white/40 text-white',
};

export const NextStep = ({
	title,
	text,
	icon,
	to,
	state,
	className,
	variant,
}) => {
	const handleClickTracking = () => {
		const hasGtag = typeof window.gtag === 'function';

		if (!hasGtag) return;

		window.gtag('event', 'click', {
			click_text: title,
			click_type: 'link',
		});
	};

	return (
		<Link
			onClick={handleClickTracking}
			className={clsx(
				NextStepVariants.Default,
				NextStepVariants[variant],
				className
			)}
			to={to}
			state={state}
		>
			<div className="flex flex-grow">
				<span
					aria-hidden="true"
					className="flex self-center justify-center w-[15%] mr-5 shrink-0 min-w-[40px] max-w-[50px] max-h-[10vw] sm:max-h-[50px]"
				>
					{icon}
				</span>
				<hgroup>
					<h3 className="text-base-f font-centra-medium">{title}</h3>
					{text ? <p>{text}</p> : null}
				</hgroup>
			</div>
			<span
				aria-hidden="true"
				className="flex content-center ml-4 transition-transform shrink-0 w-7 group-hover:translate-x-2 group-focus:translate-x-2"
			>
				<RightArrow width={20} height={14} />
			</span>
		</Link>
	);
};

NextStep.defaultProps = {
	text: '',
	state: {},
	variant: 'Dark',
	className: '',
};

NextStep.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string,
	icon: PropTypes.element.isRequired,
	to: PropTypes.string.isRequired,
	state: PropTypes.shape({
		answers: answersType,
	}),
	variant: PropTypes.string,
	className: PropTypes.string,
};
