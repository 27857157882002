import React from 'react';
import PropTypes from 'prop-types';

import { answersType } from '../../../types';

import { NextStep } from '../../atoms/next-step';

import VideoIcon from '../../../assets/video-call.svg';
import BrewIcon from '../../../assets/brew.svg';
import TelephoneIcon from '../../../assets/telephone.svg';
import CallbackIcon from '../../../assets/callback.svg';

export const NextSteps = ({ title, subtitle, steps, titleClassName }) => (
	<div className="next-steps">
		{title && subtitle ? (
			<hgroup className="mb-lg-f">
				{title ? (
					<h2 className="text-lg-f font-centra-medium mb-xs-f">
						{title}
					</h2>
				) : null}
				{subtitle ? (
					<p className="text-base-f font-centra-book">{subtitle}</p>
				) : null}
			</hgroup>
		) : null}
		{title && !subtitle ? (
			<h2 className={titleClassName}>{title}</h2>
		) : null}
		<ul>
			{steps.map((step) => (
				<li key={step.key}>{step}</li>
			))}
		</ul>
	</div>
);

NextSteps.defaultProps = {
	title: '',
	subtitle: '',
	titleClassName: '',
};

NextSteps.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	titleClassName: PropTypes.string,
	steps: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export const NextStepComponent = ({
	answers,
	urgent,
	title,
	subtitle,
	titleClassName,
	backRoute,
}) => {
	const variantProp = urgent ? 'Light' : 'Dark';
	const titleProp = urgent ? '' : title;
	const subtitleProp = urgent ? '' : subtitle;
	let stateProp = { answers };

	if (backRoute) {
		stateProp = {
			...stateProp,
			backRoute,
		};
	}
	return (
		<NextSteps
			title={titleProp}
			subtitle={subtitleProp}
			titleClassName={titleClassName}
			steps={[
				<NextStep
					title="Arrange a video call with one of our advisors"
					text="Tomorrow or day after"
					icon={
						<VideoIcon
							width="50"
							height="50"
							className="max-w-full max-h-full"
						/>
					}
					key="step1"
					to="/video-call"
					state={stateProp}
					variant={variantProp}
				/>,
				<NextStep
					title="Arrange a face to face meeting at a location that suits you"
					text="Tomorrow or day after"
					icon={
						<BrewIcon
							width="50"
							height="50"
							className="max-w-full max-h-full"
						/>
					}
					key="step2"
					to="/face-to-face"
					state={stateProp}
					variant={variantProp}
				/>,
				<NextStep
					title="Give us a call"
					text="No robots, just people"
					icon={
						<TelephoneIcon
							width="50"
							height="50"
							className="max-w-full max-h-full"
						/>
					}
					key="step3"
					to="/call"
					state={stateProp}
					variant={variantProp}
				/>,
				<NextStep
					title="Or we can call you"
					text="Choose from in the next 10 minutes to a time that suits you best"
					icon={
						<CallbackIcon
							width="50"
							height="50"
							className="max-w-full max-h-full"
						/>
					}
					key="step4"
					to="/callback"
					state={stateProp}
					variant={variantProp}
				/>,
			]}
		/>
	);
};

NextStepComponent.defaultProps = {
	urgent: false,
	title: 'Next Steps',
	subtitle: 'Get a bespoke quote for your business needs.',
	titleClassName: '',
	backRoute: null,
};

NextStepComponent.propTypes = {
	answers: answersType.isRequired,
	urgent: PropTypes.bool,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	titleClassName: PropTypes.string,
	backRoute: PropTypes.string,
};
